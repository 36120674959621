// 根据你的实际请求api.js位置路径修改
import { addCompany, editCompany } from "@/api/index";
export default {
  name: "addEdit",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      visible: this.value,
      title: "",
      submitLoading: false,
      maxHeight: 510,
      buildArray: [],
      form: {
        // 添加或编辑表单对象初始化数据
        buildId: "",
        compName: "",
        shortName: "",
        contractName: "",
        contractPhone: ""
      },
      // 表单验证规则
      formValidate: {
        compName: [{
          type: "string",
          required: true,
          message: "请输入供应商名称",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    init() {},
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          delete this.form.tvsCompanyUser;
          if (this.type == "1") {
            // 编辑
            this.submitLoading = true;
            editCompany(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          } else {
            // 添加
            this.submitLoading = true;
            addCompany(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          }
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      if (this.type == "1") {
        this.title = "编辑";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else if (this.type == "2") {
        this.title = "添加";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else {
        this.title = "详细信息";
        this.maxHeight = "100%";
      }
      // 清空数据
      //this.$refs.form.resetFields();
      if (this.type == "0" || this.type == "1") {
        // 回显数据处理
        this.form = this.data;
        delete this.form.tvsCompanyUser;
        delete this.form.tvsCompanyGoodNames;
        delete this.form.goodItems;
        delete this.form.unloadUser;
        delete this.form.checkUser;
      } else {
        // 添加
        this.form = {
          buildId: "",
          compName: "",
          shortName: "",
          contractName: "",
          contractPhone: ""
        };
      }
      this.visible = value;
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
  }
};