var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-dialog', {
    attrs: {
      "title": _vm.title,
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "50%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "drawer-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.formValidate,
      "label-position": "top"
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "供应商全称",
      "prop": "compName"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.compName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "compName", $$v);
      },
      expression: "form.compName"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "供应商简称",
      "prop": "shortName"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.shortName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shortName", $$v);
      },
      expression: "form.shortName"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "联系人",
      "prop": "contractName"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.contractName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contractName", $$v);
      },
      expression: "form.contractName"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "联系电话",
      "prop": "contractPhone"
    }
  }, [_c('Input', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.form.contractPhone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contractPhone", $$v);
      },
      expression: "form.contractPhone"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "备注",
      "prop": "remark"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 4,
      "maxlength": "250",
      "show-word-limit": ""
    },
    model: {
      value: _vm.form.remark,
      callback: function ($$v) {
        _vm.$set(_vm.form, "remark", $$v);
      },
      expression: "form.remark"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '0',
      expression: "type!='0'"
    }],
    staticClass: "drawer-footer br",
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('el-button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };